import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 编播分组分页查询
 * @param {Object} data
 */
export function getPlayGroupPage (data) {
  return request({
    url: '/ooh-product/v1/playgroup/getplaygrouppage',
    method: 'post',
    data: qs.stringify(data)
  })
}
