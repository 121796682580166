<template>
    <div>
        <Row class="p-b-5">
          <i-col span="24">
            <strong>关 键 字 ： </strong>
            <i-input v-model="groupQuery.name" style="width: 300px" placeholder="资源code，站点名称"></i-input>
            <Button size="small" icon="ios-search" type="primary" @click="SeachResource">搜索</Button>
          </i-col>
        </Row>
        <Table border  stripe :columns="skucolumndata" :data="tableData"></Table>
        <div class="paging_style">
            <Page size="small" :total="total" :page-size="groupQuery.pageSize" :current="groupQuery.pageNumber" show-total show-elevator  @on-change="changePage"></Page>
        </div>

        <Spin fix size="large" v-if="spinShow">
            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
            <div>资源替换中...</div>
        </Spin>
    </div>
</template>

<script>
import { assetList } from '@/api/product/category'
import { getPlayGroupPage } from '@/api/product/playgroup'
import { replaceGroup } from '@/api/order/productelectric'

export default {
  props: {
    params: {
      type: Object
    }
  },
  data () {
    return {
      spinShow: false,
      groupQuery: {
        orderId: 0,
        startDate: '',
        endDate: '',
        name: '',
        pageNumber: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],
      skucolumndata: [
        { title: '播放分组', key: 'name' },
        { title: '所在位置', key: 'assetName' },
        { title: '屏幕数量', key: 'resourceQuantity' },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, params) => {
            return h('Button', {
              props: { type: 'warning', size: 'small' },
              on: {
                click: () => {
                  this.ConfirmReplaceResource(params.row)
                }
              }
            }, '替换')
          }
        }
      ]
    }
  },
  mounted () {
    this.initPageData()
  },
  computed: {
    orderBean () {
      return this.$store.state.order.orderBean
    }
  },
  methods: {
    initPageData () {
      // 加载可用的城市、线路
      const assetquery = { categoryId: this.params.categoryId, publisherId: this.$store.getters.token.userInfo.publisherId, brandId: this.orderBean.brandId }
      assetList(assetquery).then(res => {
        this.groupQuery.assetIds = JSON.stringify(res.map(x => x.id))
        this.groupQuery.orderId = this.params.orderId
        this.groupQuery.startDate = this.params.startDate
        this.groupQuery.endDate = this.params.endDate

        this.SeachResource()
      })
    },
    SeachResource () {
      getPlayGroupPage(this.groupQuery).then(res => {
        this.total = res.totalRow
        this.tableData = res.list
      })
    },
    changePage (currentPage) {
      this.groupQuery.pageNumber = currentPage
      this.SeachResource()
    },
    ConfirmReplaceResource (item) {
      this.spinShow = true
      const data = {
        newgroupId: item.id,
        oldgroupId: this.params.groupId,
        oldStartDate: this.params.startDate,
        oldEndDate: this.params.endDate,
        orderId: this.params.orderId
      }

      replaceGroup(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '替换成功' })
          this.$emit('on-update')
        }
        this.spinShow = false
      })
    }
  }
}
</script>
