<template>
    <div>
        <Row class="table-title p-t-2">
            <i-col span="4">分组名称</i-col>
            <i-col span="4">所属单品</i-col>
            <i-col span="4">所属线路</i-col>
            <i-col span="4">包含屏幕数量</i-col>
            <i-col span="4">价格</i-col>
            <i-col span="4">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in list" :key="index" class="p-t-2" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
            <i-col span="4">{{resource.groupName}}</i-col>
            <i-col span="4">{{resource.productskuName}}</i-col>
            <i-col span="4">{{resource.assetName}}</i-col>
            <i-col span="4">{{resource.resourceQuantity}}</i-col>
            <i-col span="4">{{formatMoney(resource.usePrice)}}</i-col>
            <i-col span="4">
                <!-- <a @click="handleReplaceResource(resource)">替换</a> -->
                <a v-if="isManage" class="m-l-5" @click="handleChangeSchedule(resource)">档期</a>
                <a class="m-l-5 delete" v-if="!customskuId" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>

        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>

        <Modal v-model="showReplace" width="1000" title="点播屏资源替换" :footer-hide="true">
            <ReplaceElec :params="toReplace"  v-if="showReplace" @on-update="handleReplaced"></ReplaceElec>
        </Modal>
        <Modal v-model="showChangeSchedule" width="400" title="资源变更档期" :footer-hide="true">
            <editOrderItemSchedule :params="params" :chooseOrderItemId="chooseOrderItemId" @on-update-order-item="changeSchduleSuccess"/>
        </Modal>
    </div>
</template>

<script>
import ReplaceElec from '../common/ReplaceElec'
import editOrderItemSchedule from '../common/EditOrderItemSchedule'

import { toMoney } from '@/utils/wnumb_own'
import { deleteGroup, getElectricGroupPage } from '@/api/order/productelectric'

export default {
  props: {
    params: {
      type: Object
    },
    customskuId: {
      type: Number,
      default: null
    }
  },
  components: {
    ReplaceElec, editOrderItemSchedule
  },
  data () {
    return {
      list: [],
      query: { pageNumber: 1, pageSize: 10 },
      total: 0,
      showReplace: false,
      toReplace: {},

      showChangeSchedule: false, // 档期变更
      chooseOrderItemId: 0
    }
  },
  methods: {
    getPage () {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.endDate = this.params.endDate
      this.query.customskuId = this.customskuId || undefined

      getElectricGroupPage(this.query).then(res => {
        this.total = res.totalRow
        this.list = res.list
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.getPage()
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }
      })
    },
    handleDelete (resource) {
      const data = {
        orderId: this.params.orderId,
        startDate: this.params.startDate,
        endDate: this.params.endDate,
        groupId: resource.groupId
      }
      deleteGroup(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', this.params.categoryId)
        }
      })
    },
    handleReplaceResource (resource) { // 点播屏替换
      this.toReplace = Object.assign({}, resource, {
        orderId: this.params.orderId,
        categoryId: this.params.categoryId,
        startDate: this.params.startDate,
        endDate: this.params.endDate
      })
      this.showReplace = true
    },
    handleReplaced () {
      this.showReplace = false
      this.$emit('on-update-order', this.params.categoryId)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleChangeSchedule (resource) {
      this.chooseOrderItemId = resource.orderitemId
      this.showChangeSchedule = true
    },
    changeSchduleSuccess () {
      this.showChangeSchedule = false
      this.$emit('on-update-order', this.params.categoryId)
    }
  },
  mounted () {
    this.getPage()
    this.$emit('on-update-map', [])
  },
  watch: {
    params: function () {
      this.query.pageNumber = 1
      this.getPage()
    }
  }
}
</script>
